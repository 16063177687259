:root {
  --text: #2c2633;
  --first-color: #511e84;
  --first-color-alt: #6f2eb1;
  --second-color: #ff7d54;
  --second-color-alt: #f48c6c;
  --third-color: #a87fd2;
  --third-color-alt: #b883ed;
  --fourth-color: #00a1f1;
  --fourth-color-alt: #3aa6db;
  --readonly-color: #c9b3a7;
  --readonly-background: #f5f4f3;
  --youtube-color: #fa183d;
  --border-color: #d7d7d7;
  --white: #ffffff;
  --footer-color: #1f2937;
  --footer-text-color: #ffffff;
  --error: #ff3b3b;
  --success: #5cb85c;
  --warn: #f0ad4e;
  --info: #5bc0de;
  --whatsapp-color: #25D366;
  --whatsapp-color-alt: #2BB741;
  --whatsapp-bg: linear-gradient(-120deg, var(--whatsapp-color) 30%, var(--whatsapp-color-alt) 100%);
  --ff: 'Roboto', "Helvetica Neue", sans-serif;
  --fs-title: 22px;
  --fs-subtitle: 18px;
  --fs-paragraph: 18px;
  --fs-label: 20px;
  --fw-bold: bold;
  --fw-regular: 400;
  --border-radius: 0px;
  --base: #f3f6fd;
  --base-alt: #ffffff;
  --bg-tooltip-color: #111827;
  --text-tooltip-color: #ffffff;
  --animate-duration: 0.45s !important;
  --animate-delay: 0.45s !important;
}

@media only screen and (max-width: 412px) {
  :root {
    --fs-title: 18px;
    --fs-subtitle: 15px;
    --fs-paragraph: 18px;
    --border-radius: 0;
  }
}

*,
*::before,
*::after {
  outline: none !important;
  box-sizing: border-box !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--success) !important;
  text-shadow: #383636 0 -1px;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: transparent !important;
  --mat-tab-header-active-focus-indicator-color: transparent !important;
  --mat-tab-header-inactive-label-text-color: rgb(24 24 24 / 48%) !important;
  --mat-tab-header-active-hover-indicator-color: transparent !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--barra);
  --mdc-dialog-subhead-color: var(--texto);
  --mdc-dialog-supporting-text-color: var(--texto);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Muli", sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: "Muli", sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--primario);
  --mdc-checkbox-selected-hover-icon-color: var(--primario);
  --mdc-checkbox-selected-icon-color: var(--primario);
  --mdc-checkbox-selected-pressed-icon-color: var(--primario);
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--primario) !important;
  --mdc-switch-selected-handle-color: var(--primario) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primario) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primario) !important;
  --mdc-switch-selected-focus-handle-color: var(--primario) !important;
  --mdc-switch-selected-hover-handle-color: var(--primario) !important;
  --mdc-switch-selected-pressed-handle-color: var(--primario) !important;
  --mdc-switch-selected-focus-track-color: var(--terciario) !important;
  --mdc-switch-selected-hover-track-color: var(--terciario) !important;
  --mdc-switch-selected-pressed-track-color: var(--terciario) !important;
  --mdc-switch-selected-track-color: var(--terciario) !important;
}

.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--primario) !important;
  --mdc-radio-selected-hover-icon-color: var(--primario) !important;
  --mdc-radio-selected-icon-color: var(--primario) !important;
  --mdc-radio-selected-pressed-icon-color: var(--primario) !important;
  --mat-mdc-radio-checked-ripple-color: var(--primario) !important;
}

.mat-mdc-radio-button .mdc-form-field>label {
  margin: 0 !important;
  cursor: pointer !important;
}

.mat-mdc-radio-button .mdc-form-field,
.mat-mdc-checkbox .mdc-form-field {
  margin: .35rem .25rem 0 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon,
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  display: none !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
  background: transparent !important;
}

.mat-mdc-slide-toggle .mdc-form-field>label {
  margin: 0 !important;
  margin-left: .35rem !important;
  cursor: pointer !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type=number] {
  appearance: textfield !important;
}

html,
body {
  color: var(--texto);
  margin: 0;
  padding: 0;
  font-size: 30px;
}

a {
  color: var(--primario);
  text-decoration: underline;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 400;
}

html,
body {
  height: 100%;
}

.normalBorderItem {
  border: 4px #69b09f solid;
}

.lowBorderItem {
  border: 4px #f2aa5e solid;
}

.notNormalBorderItem {
  border: 4px #bb3707 solid;
}

.highBorderItem {
  border: 4px #d65959 solid;
}

.noneItem {
  background-color: #ffffff;
  border: 1px #a8a8a8 solid;
}

.color-2,
.normalItem {
  background-color: #69b09f;
  color: #ffffff;
}

.color-1,
.lowItem {
  background-color: #f2aa5e;
  color: #ffffff;
}

.color-3,
.notNormalItem {
  background-color: #bb3707;
  color: #ffffff;
}

.color-4,
.highItem,
.inappropriateItem {
  background-color: #d65959;
  color: #ffffff;
}

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: max-content;
  margin: 0 0 0.75rem;
}

.form-group .text {
  width: 100%;
  min-width: 100%;
  font-family: var(--ff);
  font-size: var(--fs-paragraph);
  color: var(--text);
}

.form-group .form-label {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  height: max-content;
  font-size: var(--fs-label);
  font-weight: var(--fw-bold);
  padding: 0;
  margin: 0;
  background: transparent;
  color: var(--text);
  z-index: 1;
}

.form-group .form-label .icon {
  display: block;
  width: max-content;
  height: max-content;
  font-size: inherit;
  color: var(--text);
  margin-left: 0.25rem;
  cursor: pointer;
}

.form-group [class*="form-control"] {
  position: relative;
  display: block;
  width: 100%;
  height: 35px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  font-family: var(--ff);
  font-size: var(--fs-paragraph);
  font-weight: var(--fw-regular);
  background: transparent !important;
  border: none;
  border-bottom: 1px solid var(--text);
  border-radius: calc(var(--border-radius) - 3px);
  color: var(--text);
  margin-top: calc(var(--fs-label) - 10px);
}

.form-control:focus {
  border-bottom-color: var(--first-color) !important;
}

.form-group [class*="form-control"].clock-timepicker:not(.input-timepicker-normal) {
  height: 150px;
  font-size: calc(var(--fs-title) * 2);
  text-align: center;
  border-radius: var(--border-radius);
  font-weight: var(--fw-bold);
  cursor: pointer;
}

.form-group [class*="form-control"].input-timepicker-normal {
  height: 40px;
  line-height: 40px;
}

.form-group [class*="form-control"].border {
  border-bottom: 1px solid var(--border-color) !important;
}

.form-group [class*="form-control"]::placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  opacity: 0.85;
}

.form-group [class*="form-control"].password {
  padding-right: calc(15px + 1rem);
}

.form-group [class*="form-control"].password::-ms-reveal {
  display: none !important;
}

.form-group [class*="form-control"].type-color {
  padding: 0.25rem 0.45rem;
  width: 55px;
}

.form-group [class*="form-control"][readonly] {
  color: var(--readonly-color) !important;
  background: var(--readonly-background) !important;
}

.form-group [class*="form-control"].bg-white {
  background: var(--base-alt);
}

.form-group .button-app.restar-color {
  position: absolute;
  bottom: 3px;
  left: calc(55px + 1.25rem);
  zoom: 0.85;
}

.form-group .form-control--editor {
  height: max-content;
  padding: 0;
}

.form-group .form-control--editor .p-editor-container {
  border: none !important;
  border-radius: calc(var(--border-radius) - 3px);
}

.form-group .form-control--editor .p-editor-container .p-editor-toolbar {
  background: var(--base);
  border: none;
  border-bottom: 1px solid var(--border-color) !important;
  border-top-right-radius: calc(var(--border-radius) - 3px);
  border-top-left-radius: calc(var(--border-radius) - 3px);
}

.form-group .form-control--editor .p-editor-container .p-editor-toolbar .ql-formats button {
  color: var(--readonly-color) !important;
}

.form-group .form-control--editor .p-editor-container .p-editor-toolbar .ql-formats button.ql-active {
  color: var(--second-color) !important;
}

.form-group .form-control--editor .p-editor-container .p-editor-toolbar .ql-formats button .ql-stroke {
  stroke: currentColor !important;
}

.form-group .form-control--editor .p-editor-container .p-editor-toolbar .ql-formats button .ql-fill {
  fill: currentColor !important;
}

.form-group .form-control--editor .p-editor-container .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border: none;
  border-bottom-right-radius: calc(var(--border-radius) - 3px);
  border-bottom-left-radius: calc(var(--border-radius) - 3px);
}

.form-group .form-control--editor .p-editor-container .p-editor-content .ql-editor {
  background: var(--base);
  color: var(--text);
  border: none;
  font-family: var(--ff) !important;
  font-size: var(--fs-paragraph) !important;
  border-bottom-right-radius: calc(var(--border-radius) - 3px);
  border-bottom-left-radius: calc(var(--border-radius) - 3px);
}

.form-group .form-control--slider .text-helper {
  position: relative;
  top: initial;
  right: initial;
  transform: initial;
  padding-right: 0;
}

.form-group .form-control--slider .slider-app {
  width: calc(100% - 1.5rem);
  margin-top: 5px;
}

.form-group .form-control--slider .slider-app .p-slider {
  width: 100%;
  background: var(--border-color);
}

.form-group .form-control--slider .slider-app .p-slider .p-slider-handle {
  background: var(--first-color);
  border-color: var(--first-color);
  outline: none;
}

.form-group .form-control--slider .slider-app .p-slider .p-slider-handle:hover {
  background: var(--first-color);
  border-color: var(--first-color);
}

.form-group .form-control--slider .slider-app .p-slider .p-slider-range {
  background: var(--first-color);
}

.form-group .form-control--multiselect-chips {
  padding: 0;
}

.form-group .form-control--multiselect-chips .p-overlay {
  top: calc(100% + 0.5rem) !important;
}

.form-group .form-control--multiselect-chips .p-multiselect {
  background: transparent !important;
  width: 100%;
  height: 100%;
  border-radius: calc(var(--border-radius) - 3px);
  border: none;
}

.form-group .form-control--multiselect-chips .p-multiselect .p-multiselect-trigger {
  width: 1.85rem;
}

.form-group .form-control--multiselect-chips .p-multiselect .p-multiselect-trigger .p-icon-wrapper .p-icon {
  display: none !important;
}

.form-group .form-control--multiselect-chips .p-multiselect .p-multiselect-trigger .p-icon-wrapper::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: calc(50% + 5px);
  right: 0.6rem;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border-top: 7px solid var(--first-color-alt);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: none;
}

.form-group .form-control--multiselect-chips .p-multiselect .p-multiselect-trigger .p-icon-wrapper::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: calc(50% - 5px);
  right: 0.6rem;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border-bottom: 7px solid var(--first-color-alt);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: none;
}

.form-group .form-control--multiselect-chips .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.form-group .form-control--multiselect-chips .p-multiselect-panel {
  background: var(--base-alt);
  color: var(--text);
  border-radius: calc(var(--border-radius) - 6px);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.form-group .form-control--multiselect-chips .p-multiselect-label {
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0 0 0 15px !important;
  color: var(--text);
}

.form-group .form-control--multiselect-chips .p-multiselect-label.p-placeholder {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  opacity: 0.85;
}

.form-group .form-control--multiselect-chips .p-multiselect-label .p-multiselect-token {
  padding: 0 0.75rem !important;
}

.form-group .form-control--multiselect-chips .p-multiselect-header {
  padding: 0.5rem;
  background: var(--base);
  border-bottom: 1px solid var(--border-color);
}

.form-group .form-control--multiselect-chips .p-multiselect-header .p-checkbox .p-checkbox-box {
  background: var(--base-alt);
}

.form-group .form-control--multiselect-chips .p-multiselect-header .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--first-color);
}

.form-group .form-control--multiselect-chips .p-multiselect-header .p-multiselect-close {
  display: none;
}

.form-group .form-control--multiselect-chips .p-multiselect-header .p-multiselect-filter-container {
  position: relative;
  display: block;
}

.form-group .form-control--multiselect-chips .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  position: relative;
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0 38px 0 15px;
  font-family: var(--ff);
  font-size: var(--fs-paragraph);
  font-weight: var(--fw-regular);
  background: var(--base-alt);
  border: 1px solid var(--border-color);
  border-radius: calc(var(--border-radius) - 8px);
  color: var(--text);
  box-shadow: none;
}

.form-group .form-control--multiselect-chips .p-multiselect-header .p-multiselect-filter-container .p-icon-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, 0);
}

.form-group .form-control--multiselect-chips .p-multiselect-items {
  padding: 0;
}

.form-group .form-control--multiselect-chips .p-multiselect-items .p-multiselect-item {
  color: var(--text);
  opacity: 0.85;
  padding: 0.5rem;
}

.form-group .form-control--multiselect-chips .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}

.form-group .form-control--multiselect-chips .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--first-color);
  background: var(--base);
}

.form-group .form-control--multiselect-chips .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background: var(--base);
  color: var(--text);
  opacity: 1;
}

.form-group .form-control--multiselect-chips .p-multiselect-items .p-multiselect-item .p-multiselect-empty-message {
  color: var(--text);
  opacity: 0.85;
}

.form-group select.form-control {
  padding: 5px 20px 0 12px;
}

.form-group textarea.form-control {
  width: 100%;
  min-height: 180px;
  max-height: 180px;
  resize: none;
}

.form-group .eyes {
  position: absolute;
  top: calc(50% + 12px);
  right: 0.5rem;
  transform: translate(0, -50%);
  font-size: 1rem;
  color: var(--first-color-alt);
  cursor: pointer;
}

.form-group .text-helper {
  display: block;
  width: max-content;
  height: 40px;
  line-height: 40px;
  font-family: var(--ff);
  font-size: var(--fs-paragraph);
  font-weight: var(--fw-bold);
  padding: 0 0.75rem;
  margin: 0;
  border-top-right-radius: calc(var(--border-radius) - 3px);
  border-bottom-right-radius: calc(var(--border-radius) - 3px);
  position: absolute;
  top: calc(50% + 12px);
  right: 0;
  transform: translateY(-50%);
  user-select: none;
}

.form-group.no-valid .form-label {
  color: var(--error);
}

.form-group.no-valid [class*="form-control"] {
  color: var(--error);
  border-bottom-color: var(--error);
}

.form-group.no-valid::after {
  content: attr(message-error);
  position: relative;
  width: 100%;
  height: max-content;
  margin: 0.25rem 0 0;
  padding: 0;
  font-family: var(--ff);
  font-size: var(--fs-label);
  font-weight: var(--fw-regular);
  color: var(--error);
  background: transparent;
}

.form-group.valid .form-label {
  color: var(--success);
}

.form-group.valid [class*="form-control"] {
  color: var(--success);
  border-bottom-color: var(--success);
}

.form-group--search {
  --showSuggestion: none;
  --topSuggestion: 0;
  --leftSuggestion: 0;
  --widthSuggestion: 0;
  margin-top: calc(var(--fs-label) - 5px);
  margin-bottom: 0;
}

.form-group--search [class*="form-control"] {
  margin-top: 0;
  padding-right: 2rem;
}

.form-group--search .icon-search {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translate(0, -50%);
}

.form-group--search .suggestion-wrapper {
  display: var(--showSuggestion);
  position: fixed;
  top: var(--topSuggestion);
  left: var(--leftSuggestion);
  width: var(--widthSuggestion);
  height: max-content;
  min-height: 0;
  max-height: calc(52px * 5);
  background-color: var(--base-alt);
  border: none;
  border-radius: var(--border-radius) !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
}

.form-group--search .suggestion-wrapper .item {
  width: 100%;
  height: 52px;
  padding: 0.45rem 0.8rem;
  text-transform: initial;
  color: var(--text);
  font-family: var(--ff);
  font-size: var(--fs-paragraph);
  font-weight: var(--fw-normal);
  border-top: 1px solid var(--border-color);
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.form-group--search .suggestion-wrapper .item .title,
.form-group--search .suggestion-wrapper .item .subtitle {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  overflow: hidden;
}

.form-group--search .suggestion-wrapper .item .subtitle {
  font-size: var(--fs-label);
  margin-top: 0.15rem;
  opacity: 0.75;
}

.form-group--search .suggestion-wrapper .item:hover {
  background: rgba(0, 0, 0, 0.03);
}

.form-group--search .suggestion-wrapper .item:first-child {
  border-top: none;
}

.swal2-container.swal2-center>.swal2-popup {
  padding: var(--border-radius) !important;
  border-radius: calc(var(--border-radius) + 4px) !important;
}

.swal2-title {
  font-family: var(--ff) !important;
  font-size: var(--fs-title) !important;
  font-weight: var(--fw-regular) !important;
  margin-bottom: 2rem !important;
}

.button-app,
.swal2-styled,
.mercadopago-button,
.mat-button {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 30px !important;
  line-height: 30px !important;
  margin: 0 !important;
  padding: 1.2rem 1rem !important;
  font-family: var(--ff) !important;
  font-size: var(--fs-paragraph) !important;
  font-weight: var(--fw-regular) !important;
  background: transparent !important;
  color: var(--first-color) !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 50px !important;
  cursor: pointer !important;
}

.button-app .icon,
.swal2-styled .icon,
.mercadopago-button .icon,
.mat-button .icon {
  margin-right: 0.5rem;
  font-size: inherit !important;
  color: inherit !important;
  opacity: 1 !important;
}

.button-app.button-edit-banner,
.swal2-styled.button-edit-banner,
.mercadopago-button.button-edit-banner,
.mat-button.button-edit-banner,
.button-app.button-change-image,
.swal2-styled.button-change-image,
.mercadopago-button.button-change-image,
.mat-button.button-change-image {
  background: var(--base-alt) !important;
  color: var(--text) !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: calc(var(--border-radius) - 6px) !important;
}

.button-app.button-edit-banner .icon,
.swal2-styled.button-edit-banner .icon,
.mercadopago-button.button-edit-banner .icon,
.mat-button.button-edit-banner .icon,
.button-app.button-change-image .icon,
.swal2-styled.button-change-image .icon,
.mercadopago-button.button-change-image .icon,
.mat-button.button-change-image .icon {
  display: inline-block;
  width: var(--fs-paragraph);
  font-size: initial;
}

.button-app.button-change-image,
.swal2-styled.button-change-image,
.mercadopago-button.button-change-image,
.mat-button.button-change-image {
  width: 35px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.button-app.button-change-image .icon,
.swal2-styled.button-change-image .icon,
.mercadopago-button.button-change-image .icon,
.mat-button.button-change-image .icon {
  margin: 0;
}

.button-app.swal2-cancel,
.swal2-styled.swal2-cancel,
.mercadopago-button.swal2-cancel,
.mat-button.swal2-cancel {
  display: none !important;
}

.button-app--first-color,
.swal2-styled--first-color,
.mercadopago-button--first-color,
.mat-button--first-color,
.button-app.swal2-confirm,
.swal2-styled.swal2-confirm,
.mercadopago-button.swal2-confirm,
.mat-button.swal2-confirm,
.button-app.mat-primary,
.swal2-styled.mat-primary,
.mercadopago-button.mat-primary,
.mat-button.mat-primary {
  background: linear-gradient(90deg, var(--first-color), var(--first-color-alt)) !important;
  color: var(--white) !important;
}

.button-app--second-color,
.swal2-styled--second-color,
.mercadopago-button--second-color,
.mat-button--second-color {
  background: linear-gradient(90deg, var(--second-color), var(--second-color-alt)) !important;
  color: var(--white) !important;
}

.button-app--third-color,
.swal2-styled--third-color,
.mercadopago-button--third-color,
.mat-button--third-color {
  background: linear-gradient(90deg, var(--third-color), var(--third-color-alt)) !important;
  color: var(--white) !important;
}

.button-app--fourth-color,
.swal2-styled--fourth-color,
.mercadopago-button--fourth-color,
.mat-button--fourth-color {
  background: linear-gradient(90deg, var(--fourth-color), var(--fourth-color-alt)) !important;
  color: var(--white) !important;
}

.button-app--info-color,
.swal2-styled--info-color,
.mercadopago-button--info-color,
.mat-button--info-color {
  background: var(--info) !important;
  color: var(--white) !important;
}

.button-app--error-color,
.swal2-styled--error-color,
.mercadopago-button--error-color,
.mat-button--error-color {
  background: var(--error) !important;
  color: var(--white) !important;
}

.button-app--whatsapp-color,
.swal2-styled--whatsapp-color,
.mercadopago-button--whatsapp-color,
.mat-button--whatsapp-color {
  background: var(--whatsapp-color) !important;
  color: var(--white) !important;
}

.button-app--youtube-color,
.swal2-styled--youtube-color,
.mercadopago-button--youtube-color,
.mat-button--youtube-color {
  position: relative;
  background: var(--youtube-color) !important;
  color: var(--white) !important;
}

.button-app--youtube-color .icon,
.swal2-styled--youtube-color .icon,
.mercadopago-button--youtube-color .icon,
.mat-button--youtube-color .icon {
  position: relative;
  margin: 0 !important;
  z-index: 2;
}

.button-app:disabled,
.swal2-styled:disabled,
.mercadopago-button:disabled,
.mat-button:disabled {
  color: #a0a0a0 !important;
  background: rgba(0, 0, 0, 0.065) !important;
  cursor: not-allowed !important;
}

.badge-app {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--error);
  width: var(--fs-label);
  height: var(--fs-label);
  border-radius: calc(var(--border-radius) - 10px);
}

.title-app,
.subtitle-app {
  display: block;
  width: 100%;
  font-family: var(--ff);
  font-size: var(--fs-title);
  font-weight: var(--fw-bold);
  color: var(--text);
  text-align: left;
  margin: 0 0 0.25rem;
  padding: 0;
}

.title-app .muted,
.subtitle-app .muted {
  font-size: calc(var(--fs-title) - 3px);
  font-weight: var(--fw-regular);
  opacity: 0.75;
}

.subtitle-app {
  font-size: var(--fs-paragraph);
  font-weight: var(--fw-regular);
}

.subtitle-app .muted {
  font-size: calc(var(--fs-paragraph) - 3px);
  font-weight: var(--fw-regular);
  opacity: 0.75;
}

[class*='azh-col--'] {
  padding: 0.5rem;
}

@media only screen and (max-width: 767px) {
  [class*='azh-col--'] {
    padding: 0.25rem;
  }
}

.azh-col-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.azh-col--1 {
  width: calc((100%) / 12);
}

.azh-col--2 {
  width: calc((100%) / 6);
}

.azh-col--3 {
  width: calc((100%) / 4);
}

.azh-col--4 {
  width: calc((100%) / 3);
}

.azh-col--5 {
  width: calc((100% / 12) * 5);
}

.azh-col--6 {
  width: calc((100%) / 2);
}

.azh-col--7 {
  width: calc((100% / 12) * 7);
}

.azh-col--8 {
  width: calc((100% / 12) * 8);
}

.azh-col--9 {
  width: calc((100% / 12) * 9);
}

.azh-col--10 {
  width: calc((100% / 12) * 10);
}

.azh-col--11 {
  width: calc((100% / 12) * 11);
}

.azh-col--12 {
  width: 100%;
}

.borderFicha {
  border: solid 1px #ddd;
  border-radius: 1%;
  word-wrap: break-word;
  max-height: 380px;
}

.borderFicha img {
  width: 110px;
  height: 110px;
  max-height: 145px;
  border-radius: 4%;
  padding: 0.2rem;
}

.borderBottom {
  border-bottom: solid 1px #ddd;
  min-height: 100px;
  word-wrap: break-word;
}

@media only screen and (max-width: 850px) {


  .azh-col-row {
    width: 100%;
  }

  .azh-col--1 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--2 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--3 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--4 {
    display: inline-block;
    width: 50%;

  }

  .azh-col--5 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--6 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--7 {
    display: inline-block;
    width: calc((100% / 12) * 7);
  }

  .azh-col--8 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--9 {
    display: inline-block;
    width: calc((100% / 12) * 9);
  }

  .azh-col--10 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--11 {
    display: inline-block;
    width: 50%;
  }

  .azh-col--12 {
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .mat-mdc-dialog-actions button {
    padding: 0.5rem !important;
  }

  .modal-meditions {
    max-width: 100vw !important;
    max-height: 100vh !important;
  }

  .azh-col-row {
    width: 100%;
  }

  .azh-col--1 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--2 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--3 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--4 {
    display: inline-block;
    width: 100%;

  }

  .azh-col--5 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--6 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--7 {
    display: inline-block;
    width: calc((100% / 12) * 7);
  }

  .azh-col--8 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--9 {
    display: inline-block;
    width: calc((100% / 12) * 9);
  }

  .azh-col--10 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--11 {
    display: inline-block;
    width: 100%;
  }

  .azh-col--12 {
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .borderFicha {
    max-height: 480px;
  }

  .borderFichaMax {
    max-height: 640px;
  }

  .borderBottom {
    min-height: 155px;
  }

  .borderBottomMin {
    min-height: 315px !important;
  }

  .azh-col--mobile-1 {
    width: calc((100%) / 12);
  }

  .azh-col--mobile-2 {
    width: calc((100%) / 6);
  }

  .azh-col--mobile-3 {
    width: calc((100%) / 4);
  }

  .azh-col--mobile-4 {
    width: calc((100%) / 3);
  }

  .azh-col--mobile-5 {
    width: calc((100% / 12) * 5);
  }

  .azh-col--mobile-6 {
    width: calc((100%) / 2);
  }

  .azh-col--mobile-7 {
    width: calc((100% / 12) * 7);
  }

  .azh-col--mobile-8 {
    width: calc((100% / 12) * 8);
  }

  .azh-col--mobile-9 {
    width: calc((100% / 12) * 9);
  }

  .azh-col--mobile-10 {
    width: calc((100% / 12) * 10);
  }

  .azh-col--mobile-11 {
    width: calc((100% / 12) * 11);
  }

  .azh-col--mobile-12 {
    width: 100%;
  }
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 3.5rem !important;
}

.mt-8 {
  margin-top: 4rem !important;
}

.mt-9 {
  margin-top: 4.5rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pr-3 {
  padding-right: 1.5rem !important;
}

.pl-3 {
  padding-left: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 3.5rem !important;
}

.pb-8 {
  padding-bottom: 4rem !important;
}

.pb-9 {
  padding-bottom: 4.5rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pr-4 {
  padding-right: 2rem !important;
}

.pl-4 {
  padding-left: 2rem !important;
}

.flex {
  display: flex !important;
}

.flex--column {
  flex-direction: column !important;
}

.flex--row {
  flex-direction: row !important;
}

.flex--wrap {
  flex-wrap: wrap !important;
}

.flex.xLeft {
  justify-content: flex-start !important;
}

.flex.xRight {
  justify-content: flex-end !important;
}

.flex.xCenter {
  justify-content: center !important;
}

.flex.xBetween {
  justify-content: space-between !important;
}

.flex.xAround {
  justify-content: space-around !important;
}

.flex.yLeft {
  align-items: flex-start !important;
}

.flex.yRight {
  align-items: flex-end !important;
}

.flex.yCenter {
  align-items: center !important;
}

.w-100 {
  width: 100% !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-center {
  text-align: center !important;
}

.card-app {
  width: 100%;
  background: var(--base-alt);
  margin-bottom: 1rem;
  border-radius: 8px;
  padding: 0;
}

.card-app__head,
.card-app__body {
  position: relative;
}

.card-app:last-child {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .card-app {
    padding: 0.5rem;
    border-radius: 0;
    box-shadow: none;
    zoom: 0.95;
  }
}

@media only screen and (max-width: 415px) {
  .card-app {
    zoom: 0.9;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.left {
  justify-content: left;
  text-align: left;
}

.right {
  justify-content: right;
  text-align: right;
}

.bold {
  font-weight: bold !important;
}

[az-tooltip] {
  position: relative !important;
}

[az-tooltip]:hover .az-tooltip {
  display: block !important;
}

.cerrar-modal {
  position: absolute;
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  color: var(--texto);
  cursor: pointer;
  z-index: 10;
}

.titulo-modal {
  font-family: "Muli", sans-serif;
  color: var(--primario);
  font-weight: 600;
}

div.mat-mdc-select-panel {
  background-color: #ffffff !important;
  color: var(--text, rgba(0, 0, 0, 0.87));
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option {
  color: var(--text, rgba(0, 0, 0, 0.87)) !important;
}

.mat-mdc-icon-button,
.mat-mdc-select,
.mat-mdc-select-value-text {
  color: var(--text, rgba(0, 0, 0, 0.87)) !important;
}

.mat-mdc-select-min-line {
  fill: var(--text, rgba(0, 0, 0, 0.87)) !important;
}

.mat-mdc-paginator-icon {
  fill: var(--text) !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.ngx-charts-tooltip-content.position-right,
.ngx-charts-tooltip-content.position-right {
  display: none !important;
}

.shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.mat-mdc-tab-list {
  padding: .25rem .5rem;
  transform: translateX(0) !important;
  zoom: .85;
}

.mat-mdc-tab-labels {
  background: #E5E5E6;
  padding: .25rem;
  border-radius: 8px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  max-width: 50% !important;
  height: 40px !important;
  border-radius: 8px;
  background: transparent;
  box-shadow: none !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab.mdc-tab-indicator--active {
  background: var(--base-alt) !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab:hover {
  background: transparent !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab.mdc-tab-indicator--active {
  background: var(--base-alt) !important;
}

.mdc-tab__text-label {
  color: var(--text) !important;
}

.mdc-tab-indicator {
  background: transparent !important;
}

.mat-mdc-tab-ripple,
.mdc-tab__ripple {
  display: none !important;
}

.mat-mdc-tab-header-pagination {
  display: none !important;
}

.paddingChart {
  padding-left: .5rem;
  padding-right: .5rem;
}

.ngx-charts-outer .line-chart.chart,
.ngx-charts-outer .area-chart.chart,
.ngx-charts-outer .bubble-chart.chart {
  width: 100% !important;
  transform: translate(50px, 18px) !important;
}

.ngx-charts-outer .area-chart.chart {
  transform: translate(55px, 18px) !important;
}

.ngx-charts-outer .bubble-chart.chart {
  transform: translate(55px, 18px) !important;
}

.opacity-65 {
  opacity: 0.65;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #32f32b;
}